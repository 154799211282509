import Fraction from 'fraction.js'

export const fractionRegex = /[1-9][0-9]*\/[1-9][0-9]*/g

export const decimalOrFractionRegex = /^\d+(\.\d+)?$|^(\d+ )?(\d+\/\d+)$/
// Here are some examples of strings that will match the updated regex:
// 123
// 123.45
// 3/4
// 100/7
// 1 1/2
// 2 3/5

// Here are some examples of strings that will not match the updated regex:
// 123.45.67 (multiple decimal points)
// 123/4/5 (multiple slashes)
// 123.45/67 (mixed decimal and fraction)
// -123 (negative number)
// 123e4 (exponential notation)
// 1/1 (numerator is equal to denominator)
// 2/1 (numerator is greater than denominator)
// 1/0 (division by zero)
// 1 2 (missing slash)
// 1 1/2/3 (multiple slashes)

export function convertToDecimal(fraction) {
  if (fraction?.toString()?.endsWith('/')) {
    try {
      const x = new Fraction(fraction + '1')
      return parseFloat(x.n / x.d).toFixed(2)
    } catch (e) {
      return NaN
    }
  }

  if (fractionRegex.test(fraction)) {
    try {
      const x = new Fraction(fraction)
      return parseFloat(x.n / x.d).toFixed(2)
    } catch (e) {
      return NaN
    }
  }

  return Number(fraction)
}

// test
// console.log(convertToDecimal('1/2')) // 0.50
// console.log(convertToDecimal('1/3')) // 0.33
// console.log(convertToDecimal('1/4')) // 0.25
// console.log(convertToDecimal('1/')) // 1
// console.log(convertToDecimal('1 1/2')) // 1.5
//
// console.log(convertToDecimal('-1123')) // -1123
// console.log(convertToDecimal('1123')) // 1123
// console.log(convertToDecimal('1123.111')) // 1123.111
//
// console.log(convertToDecimal(-1123)) // -1123
// console.log(convertToDecimal(1123)) // 1123
// console.log(convertToDecimal(1123.111)) // 1123.111

export function convertToFraction(decimal) {
  if (decimal?.toString()?.endsWith('/')) {
    try {
      const x = new Fraction(decimal + '1')
      return x.n / x.d
    } catch (e) {
      return NaN
    }
  }

  try {
    return new Fraction(decimal).toFraction(true)
  } catch (e) {
    return NaN
  }
}

// console.log('===================================') // 0.25

// test
// console.log(convertToFraction('1/2')) // "1/2"
// console.log(convertToFraction('1/3')) // "1/3"
// console.log(convertToFraction('1/4')) // "1/4"
// console.log(convertToFraction('1/')) // 1
//
// console.log(convertToFraction('0.50')) // "1/2"
// console.log(convertToFraction(0.50)) // "1/2"
// console.log(convertToFraction(-0.50)) // "-1/2"
// console.log(convertToDecimal(1.5)) // "1 1/2"
//
// console.log(convertToFraction('-1123')) // "-1123"
// console.log(convertToFraction('1123')) // "1123"
// console.log(convertToFraction('1123.111')) // "1123 111/1000"
//
// console.log(convertToFraction(-1123)) // "-1123"
// console.log(convertToFraction(1123)) // "1123"
// console.log(convertToFraction(1123.111)) // "1123 111/1000"
